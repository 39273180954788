
window.Tasks = window.Tasks || {
  filterSelects: {},
  markComplete: (element) => {
    const taskId = document.getElementById('task_id').value;
    const newState = element.checked ? 'complete' : 'incomplete'
    const errorCallback = () => {
      $('#mark-complete-task').length && $('#mark-complete-task').prop('checked', false)
    }
    Tasks.changeTaskState(taskId, newState, errorCallback);
  },

  changeTaskState: (id, newState, errorCallback = undefined, withDrawer = true) => {
    if (typeof newState !== 'string') {
      newState = newState.value;
      withDrawer = false;
    }
    const url = `/account/tasks/${id}/mark_${newState}`;
    $.ajax({
      url: url,
      method: 'post',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', $('meta[name=csrf-token').attr('content')) },
      data: { open_drawer: withDrawer },
      error: (xhr, options, error) => {
        alert(xhr.responseText || 'There was an unexpected error when completing the task');
        errorCallback && errorCallback()
      }
    })
  },

  changeTaskStateFromDropdown: (id, element, oldState) => {
    const newState = element.value;
    element.classList.add(newState);
    element.classList.remove(oldState)
    const errorCallback = () => {
      element.value = oldState;
      element.classList.add(oldState);
      element.classList.remove(newState);
    }
    Tasks.changeTaskState(id, newState, errorCallback, false);
  },

  updateAssigneeList: (element) => {
    if (element.selectedOptions.length > 0) {
      const selectedOptions = $(element).val();
      $.ajax({
        method: 'get',
        url: `/account/teams/${Tasks.teamId()}/tasks/assignee_list`,
        data: { assignees: selectedOptions }
      });
    }
  },

  selectedTasks: () => {
    return Array.from($('tbody > tr > td:first-child input[type="checkbox"]:checked')).map(ele => ele.value);
  },

  totalTasks: () => {
    return Array.from($('tbody > tr > td:first-child input[type="checkbox"]')).map(ele => ele.value);
  },

  toggleSelectAll: (element) => {
    $('.checkbox_select').prop('checked', element.checked);
    if (element.checked) {
      window.tasksDatatable.rows().select();
    } else {
      window.tasksDatatable.rows().deselect();
    }
    Tasks.updateSelectedTaskCount();
  },

  bulkUpdateTaskStatus: (element) => {
    const status = element.value;
    Tasks.updateTaskStatus(status, Tasks.selectedTasks());
    $('#task_ids').prop('checked', false);
  },

  selectTask() {
    $('.checkAllTasks').prop('checked', Tasks.selectedTasks().length === Tasks.totalTasks().length);
    Tasks.updateSelectedTaskCount();
  },

  updateSelectedTaskCount: (length) => {
    length = length || Tasks.selectedTasks().length;
    if (length > 0) {
      $(".task_context").addClass("d-none");
      $(".selected_task_count").removeClass("d-none");
      $(".selected_task_count").html(length + " Tasks Selected");
      $(".mass_updation").removeClass('d-none');
      $(".new_task_btn").addClass('d-none');
    } else {
      $(".task_context").removeClass("d-none");
      $(".selected_task_count").addClass("d-none");
      $(".mass_updation").addClass('d-none');
      $(".new_task_btn").removeClass('d-none');
    }
  },

  changeStatus: (element) => {
    const id = [element.id.split('_')[1]];
    const changeStatusClassCallback = () => {
      element.classList.remove('complete', 'incomplete', 'archived');
      element.classList.add(element.value);
    };
    Tasks.updateTaskStatus(element.value, id, changeStatusClassCallback);
  },

  updateTaskStatus: (status, idArray, cb) => {
    $.ajax({
      url: `/account/teams/${Tasks.teamId()}/tasks/update_status`,
      type: 'post',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', $('meta[name=csrf-token').attr('content')) },
      data: { status: status, selected_tasks: idArray },
      error: (xhr, options, error) => {
        alert(xhr.responseText || 'There was an unexpected error when updating the task(s)');
      }
    }).then(() => {
      Tasks.updateSelectedTaskCount();
      cb && cb();
    });
  },

  saveAssignees: (element) => {
    const taskIds = document.getElementById("tasks").value;
    const assigneeIds = $("#selected_assignees").val();
    $.ajax({
      method: "put",
      url: `/account/teams/${Tasks.teamId()}/tasks/add_assignees`,
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', $('meta[name=csrf-token').attr('content')) },
      data: { tasks: taskIds.split(" "), assignees: assigneeIds },
      success: function (_) {
        // for some reason $(ele).modal() is not working inside here. It gives an error that modal() is not a function
        // so we are having to manually hide the modal and backdrop
        document.getElementById('addAssignee').style.display = 'none';
        Array.from(document.getElementsByClassName('modal-backdrop')).forEach((ele) => ele.style.display = 'none')
        window.tasksDatatable.draw('full-hold');
      }
    });
  },

  hideTaskDrawer: (element) => {
    document.getElementById("task-detail-overlay").remove("show")
  },

  teamId: () => {
    return document.getElementById('team_id').value
  }
}

$(document).on("sprinkles:update:tasks", () => {
  window.tasksDatatable.draw('full-hold');
});
